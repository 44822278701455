import Cookies from "universal-cookie";
import { COOKIE_MAX_AGE } from "../constants/GlobalConstants";

let setAuthorizationCookie = (token) => {
    const cookies = new Cookies();
    cookies.set("authorization", token, {
        path: "/",
        domain: ".programmingpathshala.com",
        maxAge: COOKIE_MAX_AGE,
    });
    // Uncomment to run locally
    //cookies.set("authorization", token, { maxAge: COOKIE_MAX_AGE });
};

/**
 * TODO :
 * when implementing /logout, convert access_token to persistent cookie
 * else it will be deleted once browser closes
 */
let setCookieWhenRedirectingAfterGoogleSignIn = (id_token, access_token) => {
    const cookies = new Cookies();

    cookies.set("id_token", id_token, {
        path: "/",
        domain: ".programmingpathshala.com",
    });
    // Uncomment to run locally
    // cookies.set("id_token", id_token);

    cookies.set("access_token", access_token, {
        path: "/",
        domain: ".programmingpathshala.com",
    });
    // Uncomment to run locally
    // cookies.set("access_token", access_token);
};

export { setAuthorizationCookie, setCookieWhenRedirectingAfterGoogleSignIn };
